export const SET_AUTH = "SET_AUTH";
export const SET_APP_CLIENT = "SET_APP_CLIENT";

export const set_auth = auth => {
    return { type: SET_AUTH, payload: { auth: auth } };
};

export const set_app_client = app_client => {
    return { type: SET_APP_CLIENT, payload: { app_client: app_client } };
};
