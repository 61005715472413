import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ApiHelper, AuthHelper } from "../helpers";
import logo from "../assets/images/page-logo.png";

class Login extends Component {
    state = {
        from: null,
        isLoading: false,
        hasError: false,
        email: "",
        password: ""
    };

    componentDidMount() {
        if (AuthHelper.check()) {
            this.props.history.push("/dashboard");
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    submit = e => {
        e.preventDefault();

        this.setState({
            isLoading: true,
            hasError: false
        });

        const data = {
            email: this.state.email,
            password: this.state.password
        };

        ApiHelper.request("/auth", data)
            .then(response => {
                this.setState({
                    isLoading: false,
                    hasError: false
                });

                AuthHelper.setAuth(response.data.auth);
                this.props.history.push("/dashboard");
            })
            .catch(e => {
                console.error(e);

                this.setState({
                    isLoading: false,
                    hasError: true
                });
            });
    };

    render() {
        return (
            <div className="login-screen">
                <div className="text-center pt-5">
                    <img src={logo} alt="Senft GmbH" title="Senft GmbH Logo" style={{ height: "70px" }} />
                    <div style={{ color: "rgba(0,0,0,0.6)", cursor: "default" }}>KUNDEN / PARTNER - PORTAL</div>
                </div>

                <div className="row">
                    <div className="col-sm-4 offset-sm-4 mt-5">
                        {this.state.hasError ? <div className="alert alert-danger">Login fehlgeschlagen.</div> : null}

                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.submit}>
                                    <div className="form-group">
                                        <label>E-Mail Adresse:</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Passwort:</label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.onChange}
                                            className="form-control"
                                        />
                                    </div>
                                    <input
                                        type="submit"
                                        value="Einloggen"
                                        className="btn btn-block btn-success"
                                        disabled={this.state.isLoading}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
