import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Invoices extends Component {
    render() {
        return (
            <div className="container">
                <div className="content">
                    <div className="float-right">
                        <Link to="/invoices/create" className="btn btn-secondary">
                            Rechnung erstellen
                        </Link>
                    </div>
                    <h3>Rechnungen</h3>
                </div>
            </div>
        );
    }
}
