import React from "react";

const LoadingSpinner = props => {
    let { children, ...rest } = props;

    return (
        <div className="loading-spinner" {...rest}>
            <div className="lds-roller">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>

            {children ? <div className="loading-spinner-message">{children}</div> : null}
        </div>
    );
};

export { LoadingSpinner };
