import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthHelper, ApiHelper } from "../../helpers";
import { set_app_client } from "../../redux/actions";
import logo from "../../assets/images/page-logo.png";

class Layout extends Component {
    logout = () => {
        if (window.confirm("Wirklich ausloggen?")) {
            AuthHelper.logout();
            this.props.history.push("/");
        }
    };

    componentDidMount() {
        this.setState({
            isLoading: true
        });

        ApiHelper.request("/main-info").then(response => {
            this.props.setAppClient(response.data.app_client);

            this.setState({
                isLoading: false
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Senft GmbH Portal" style={{ height: "30px" }} />
                    </Link>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink to="/dashboard" className="nav-link">
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/projects" className="nav-link">
                                    Bauprojekte
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/invoices" className="nav-link">
                                    Rechnungen
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/settings" className="nav-link">
                                    Einstellungen
                                </NavLink>
                            </li>
                        </ul>

                        <ul className="navbar-nav float-right">
                            <li className="nav-item">
                                <a href="#logout" className="nav-link" onClick={this.logout}>
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="content-container">{this.props.children}</div>
                <div className="text-center mt-5 pb-5 small">
                    Powered by{" "}
                    <a href="https://webtypen.de/" target="_blank" rel="noopener noreferrer">
                        webtypen.de
                    </a>
                    <br />
                    Copyright &copy; by{" "}
                    <a href="https://www.senftgmbh.de/" target="_blank" rel="noopener noreferrer">
                        Senft GmbH
                    </a>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = dispatch => ({
    setAppClient: data => dispatch(set_app_client(data))
});
export default connect(
    null,
    mapDispatch
)(withRouter(Layout));
