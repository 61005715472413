import { SET_AUTH, SET_APP_CLIENT } from "../actions";
import { AuthHelper } from "../../helpers/AuthHelper";

AuthHelper.getAuthFromLocalStorage();
const initialStore = {
    auth: AuthHelper.getAuthFromLocalStorage(),
    app_client: null
};

const appReducer = (state = initialStore, action) => {
    switch (action.type) {
        case SET_AUTH:
            return {
                ...state,
                auth: action.payload.auth
            };
        case SET_APP_CLIENT:
            return {
                ...state,
                app_client: action.payload.app_client
            };
        default:
            return state;
    }
};
export default appReducer;
