import React, { Component } from "react";

export default class Projects extends Component {
    render() {
        return (
            <div className="container">
                <div className="content">
                    <h3>Bauprojekte / Aufträge</h3>
                    Folgt bald ...
                </div>
            </div>
        );
    }
}
