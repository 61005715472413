import $ from "jquery";
import { AuthHelper } from ".";

class ApiHelperContainer {
    constructor() {
        this.url = process.env.REACT_APP_VELRACT_URL.trim();
        if (this.url.substring(this.url.length - 1) === "/") {
            this.url = this.url.substring(0, this.url.length - 1);
        }
    }

    request(path, data, method) {
        if (data === undefined) {
            data = {};
        }

        data = this.prepareApiRequestData(data);
        return $.post(this.buildApiUrl(path), data);
    }

    buildApiUrl(path) {
        path = path.trim();
        if (path.substring(0, 1) !== "/") {
            path = "/" + path;
        }

        return this.url + "/api/app-client" + path;
    }

    prepareApiRequestData(data) {
        if (AuthHelper.check()) {
            const user = AuthHelper.getUser();

            if (user) {
                data.auth_api_key = user.api_key;
                data.auth_email = user.email;
            }
        }

        return data;
    }

    rawRequest(url, data, method) {
        // return $.ajax({
        //     url: url,
        //     method: method ? method : "POST"
        // });
    }
}

const ApiHelper = new ApiHelperContainer();
export { ApiHelper };
