import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumericHelper, ApiHelper, ProjectsHelper } from "../../helpers";
import { LoadingSpinner } from "../../components";

class CreateInvoice extends Component {
    state = {
        isLoading: true,
        isAutofilling: false,
        isSaving: false,
        hasError: null,
        project: null,
        positions: [],
        preparedPositions: [],
        price: 0
    };

    priceCalcHelper = 0;
    positionsCount = 0;

    componentDidMount() {
        this.setState({
            isLoading: true,
            project: null,
            positions: [],
            price: 0
        });

        setTimeout(() => {
            ApiHelper.request("/projects/details", {
                project_id: this.props.match.params.project
            }).then(response => {
                this.setState({
                    isLoading: false,
                    project: response.project ? response.project : null
                });

                if (this.props.match.params.id === "new") {
                    this.autoFillInvoice();
                }
            });
        }, 500);
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    createPosition(number = "", title = "", quantity = 1, unit = "m", price_single = 0) {
        return {
            number: number,
            title: title,
            quantity: quantity,
            unit: unit,
            price_single: price_single
        };
    }

    addPosition = () => {
        this.positionsCount++;
        let positions = this.state.positions;
        let pos = this.createPosition();
        pos.count = this.positionsCount;

        positions.push(pos);

        this.setState({
            positions: positions
        });
    };

    removePosition = posCount => {
        if (window.confirm("Soll die ausgewählte Position wirklich entfernt werden?")) {
            let positions = this.state.positions;
            for (let i in positions) {
                if (positions[i].count === posCount) {
                    positions.splice(i, 1);
                }
            }

            this.setState({
                positions: positions
            });
        }
    };

    positionChange = e => {
        let positions = this.state.positions;
        let hasChange = false;
        for (let i in positions) {
            if (positions[i].count === parseInt(e.target.getAttribute("data-position"), 10)) {
                positions[i][e.target.name] = e.target.value;
                hasChange = true;
                break;
            }
        }

        if (hasChange) {
            this.setState(
                {
                    positions: positions
                },
                () => {
                    this.calcPrice();
                }
            );
        }
    };

    calcPrice() {
        this.priceCalcHelper++;
        setTimeout(() => {
            this.priceCalcHelper--;

            if (this.priceCalcHelper === 0) {
                let price = 0;
                for (let i in this.state.preparedPositions) {
                    let quantity = this.state.preparedPositions[i].quantity;
                    if (this.state.preparedPositions[i].changed) {
                        quantity =
                            this.state.preparedPositions[i].changed_quantity !== null
                                ? this.state.preparedPositions[i].changed_quantity
                                : 0;
                    }
                    price = price + parseFloat(quantity) * parseFloat(this.state.preparedPositions[i].price_single);
                }

                for (let i in this.state.positions) {
                    price =
                        price +
                        parseFloat(this.state.positions[i].quantity) * parseFloat(this.state.positions[i].price_single);
                }

                this.setState({
                    price: price
                });
            }
        }, 250);
    }

    autoFillInvoice = () => {
        this.setState({
            isAutofilling: true,
            preparedPositions: []
        });

        setTimeout(() => {
            ApiHelper.request("/projects/nu-auftrag/positions", {
                project_id: this.props.match.params.project
            }).then(response => {
                let prepared = [];

                if (response.positions && response.positions.length > 0) {
                    for (let i in response.positions) {
                        const pos = response.positions[i];
                        let data = this.createPosition(pos.number, pos.title, pos.quantity, pos.unit, pos.price_single);
                        data.key = pos.key;
                        data.changed = false;
                        data.changed_quantity = null;
                        prepared.push(data);
                    }
                }

                this.setState(
                    {
                        isAutofilling: false,
                        preparedPositions: prepared
                    },
                    () => {
                        this.calcPrice();
                    }
                );
            });
        }, 500);
    };

    togglePreparedChange = key => {
        let arrPos = null;
        let prepared = this.state.preparedPositions;
        for (let i in prepared) {
            if (prepared[i].key === key) {
                arrPos = i;
                break;
            }
        }

        if (!arrPos) {
            return;
        }

        prepared[arrPos].changed = !prepared[arrPos].changed;
        this.setState(
            {
                preparedPositions: prepared
            },
            () => this.calcPrice()
        );
    };

    changePrepared = e => {
        let arrPos = null;
        let prepared = this.state.preparedPositions;
        for (let i in prepared) {
            if (prepared[i].key.toString() === e.target.getAttribute("data-position")) {
                arrPos = i;
                break;
            }
        }

        if (!arrPos) {
            return;
        }

        prepared[arrPos]["changed_" + e.target.name] = e.target.value;
        this.setState(
            {
                preparedPositions: prepared
            },
            () => this.calcPrice()
        );
    };

    save = () => {
        this.setState({
            isSaving: true,
            hasError: null
        });

        setTimeout(() => {
            ApiHelper.request("/invoices/save", {
                id: this.props.match.params.id,
                project_id: this.props.match.params.project,
                preparedPositions: this.state.preparedPositions,
                positions: this.state.positions
            })
                .then(response => {
                    this.props.history.push("/invoices/" + response.invoice_id + "/details");
                })
                .catch(e => {
                    console.error(e);

                    this.setState({
                        isSaving: false,
                        hasError:
                            "Es ist ein Fehler beim Speichern der Rechnung aufgetreten. Bitte probieren Sie es in wenigen Augeblicken erneut"
                    });
                });
        }, 500);
    };

    render() {
        let form;
        if (this.state.isLoading) {
            form = <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>;
        } else if (this.state.isSaving) {
            form = <LoadingSpinner>Die Rechnung wird gespeichert ...</LoadingSpinner>;
        } else if (this.state.project) {
            form = (
                <>
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <b>Auftraggeber:</b>
                                    <br />
                                    Senft GmbH
                                    <br />
                                    Niederlassung Herten
                                    <br />
                                    Zechenstr.19
                                    <br />
                                    45699 Herten
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <b>Bauprojekt:</b>
                                    <br />
                                    {this.state.project.customer.name}
                                    <br />
                                    {this.state.project.address.street} {this.state.project.address.housenumber}{" "}
                                    {this.state.project.location ? " - " + this.state.project.location : ""}
                                    <br />
                                    {this.state.project.address.zip} {this.state.project.address.city}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5" />
                    <button
                        className="btn btn-secondary btn-sm float-right"
                        onClick={() => {
                            if (
                                window.confirm(
                                    "Soll die Rechnung automatisch mit den Daten des Auftrages ausgefüllt werden?\nDie bereits vorgenommenen Änderungen an den Angebotspositionen werden dabei überschrieben!"
                                )
                            ) {
                                this.autoFillInvoice();
                            }
                        }}
                    >
                        Angebotspositionen zurücksetzen
                    </button>
                    <h4>Positionen</h4>
                    {this.state.isAutofilling ? (
                        <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
                    ) : (
                        <>
                            {this.state.hasError ? (
                                <div className="alert alert-danger">{this.state.hasError}</div>
                            ) : null}

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "35px" }} />
                                        <th style={{ width: "140px" }}>Nr.</th>
                                        <th>Position</th>
                                        <th className="text-right" style={{ width: "120px" }}>
                                            Menge
                                        </th>
                                        <th className="text-right" style={{ width: "120px" }}>
                                            Einzelpreis
                                        </th>
                                        <th className="text-right" style={{ width: "120px" }}>
                                            Gesamtpreis
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.preparedPositions.map(position => {
                                        return (
                                            <tr key={"prepared_pos_" + position.key}>
                                                <td>
                                                    <button
                                                        className="btn btn-light btn-sm"
                                                        style={{ width: "34px", height: "31px", color: "#a2a2a2" }}
                                                        onClick={() => this.togglePreparedChange(position.key)}
                                                    >
                                                        <FontAwesomeIcon icon={position.changed ? "undo" : "edit"} />
                                                    </button>
                                                </td>
                                                <td>{position.number}</td>
                                                <td>{position.title}</td>
                                                <td className="text-right">
                                                    {position.changed ? (
                                                        <input
                                                            type="number"
                                                            className="form-control text-right"
                                                            name="quantity"
                                                            value={
                                                                position.changed_quantity === null
                                                                    ? 0
                                                                    : position.changed_quantity
                                                            }
                                                            onChange={this.changePrepared}
                                                            data-position={position.key}
                                                        />
                                                    ) : (
                                                        position.quantity
                                                    )}
                                                </td>
                                                <td className="text-right">
                                                    {NumericHelper.number_format(position.price_single, 2, ",", ".")}
                                                </td>
                                                <td className="text-right">
                                                    {NumericHelper.number_format(
                                                        position.price_single * position.quantity,
                                                        2,
                                                        ",",
                                                        "."
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    {this.state.positions.map(position => {
                                        return (
                                            <tr key={"pos_" + position.count}>
                                                <td>
                                                    <button
                                                        className="btn btn-light btn-sm"
                                                        style={{ width: "34px", height: "31px", color: "#a2a2a2" }}
                                                        onClick={() => this.removePosition(position.count)}
                                                    >
                                                        <FontAwesomeIcon icon="trash" />
                                                    </button>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="number"
                                                        data-position={position.count}
                                                        value={position.number}
                                                        onChange={this.positionChange}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="title"
                                                        data-position={position.count}
                                                        value={position.title}
                                                        onChange={this.positionChange}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control text-right"
                                                        name="quantity"
                                                        data-position={position.count}
                                                        value={position.quantity}
                                                        onChange={this.positionChange}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control text-right"
                                                        name="price_single"
                                                        data-position={position.count}
                                                        value={position.price_single}
                                                        onChange={this.positionChange}
                                                    />
                                                </td>
                                                <td className="text-right" style={{ paddingTop: "20px" }}>
                                                    {NumericHelper.number_format(
                                                        position.quantity * position.price_single,
                                                        2,
                                                        ",",
                                                        "."
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <button className="btn btn-primary float-right" onClick={this.addPosition}>
                                Position hinzufügen
                            </button>
                        </>
                    )}
                    <div className="clearfix" />

                    <div className="float-right mt-3 pt-3" style={{ borderTop: "2px solid #000", width: "300px" }}>
                        <span>Gesamtpreis:</span>
                        <span className="float-right">
                            {NumericHelper.number_format(this.state.price, 2, ",", ".")}
                        </span>
                    </div>
                    <div className="clearfix" />

                    <div className="text-right mt-5">
                        <hr />
                        <button className="btn btn-success" onClick={this.save}>
                            Rechnung speichern
                        </button>
                    </div>
                </>
            );
        } else {
            form = (
                <div className="alert alert-danger">
                    Das aufgerufene Bauprojekt wurde nicht gefunden, weshalb keine Rechnung erstellt werden kann.
                </div>
            );
        }

        return (
            <div className="container">
                <div className="content">
                    <div className="float-right">
                        {this.state.project ? (
                            <a
                                href={ProjectsHelper.generateNuAuftragUrl(this.state.project)}
                                target="_blank"
                                className="btn btn-primary btn-sm"
                                rel="noopener noreferrer"
                            >
                                Auftrag öffnen
                            </a>
                        ) : null}
                    </div>
                    <h3>Rechnung erstellen</h3>

                    {form}
                </div>
            </div>
        );
    }
}

export default withRouter(CreateInvoice);
