import { AuthHelper } from ".";

class ProjectsHelperContainer {
    generateNuAuftragUrl = project => {
        const user = AuthHelper.getUser();
        return (
            process.env.REACT_APP_VELRACT_URL.trim() +
            "exports/app-client/projects/nu-auftrag?auth_api_key=" +
            user.api_key +
            "&auth_email=" +
            user.email +
            "&data=" +
            project.id
        );
    };
    
    generateInvoiceUrl = invoice => {
        const user = AuthHelper.getUser();
        return (
            process.env.REACT_APP_VELRACT_URL.trim() +
            "exports/app-client/invoice?auth_api_key=" +
            user.api_key +
            "&auth_email=" +
            user.email +
            "&data=" +
            invoice.id
        );
    };
}

const ProjectsHelper = new ProjectsHelperContainer();
export { ProjectsHelper };
