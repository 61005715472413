import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ApiHelper, ProjectsHelper } from "../../helpers";
import { LoadingSpinner } from "../../components";

class Details extends Component {
    state = {
        isLoading: true,
        invoice: null
    };

    componentDidMount() {
        ApiHelper.request("/invoices/details", {
            invoice_id: this.props.match.params.id
        }).then(response => {
            this.setState({
                isLoading: false,
                invoice: response.invoice
            });
        });
    }

    render() {
        let { invoice } = this.state;

        let page;
        if (this.state.isLoading) {
            page = <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>;
        } else if (this.state.invoice) {
            page = (
                <div>
                    <div className="card">
                        <div className="card-body">
                            <b>Bauprojekt:</b>
                            <br />
                            {invoice.project.customer.name}
                            <br />
                            {invoice.project.address.street} {invoice.project.address.housenumber}{" "}
                            {invoice.project.location ? " - " + invoice.project.location : ""}
                            <br />
                            {invoice.project.address.zip} {invoice.project.address.city}
                        </div>
                    </div>
                </div>
            );
        } else {
            page = <div className="alert alert-warning">Die aufgerufene Rechnung konnte nicht geladen werden.</div>;
        }

        return (
            <div className="container">
                <div className="content">
                    <div className="float-right">
                        {invoice ? (
                            <a
                                href={ProjectsHelper.generateInvoiceUrl(invoice)}
                                target="_blank"
                                className="btn btn-primary btn-sm"
                                rel="noopener noreferrer"
                            >
                                Leistungsnachweis öffnen
                            </a>
                        ) : null}
                    </div>
                    <h3>
                        Rechnung ansehen <small>{invoice ? invoice.number : null}</small>
                    </h3>

                    {page}
                </div>
            </div>
        );
    }
}

export default withRouter(Details);
