import React, { Component } from "react";

export default class Settings extends Component {
    render() {
        return (
            <div className="container">
                <div className="content">
                    <h3>Einstellungen</h3>
                    Folgt bald ...
                </div>
            </div>
        );
    }
}
