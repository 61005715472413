import { createStore, combineReducers, compose } from "redux";
import appReducer from "./reducers/appReducer";

const rootReducer = combineReducers({
    app: appReducer,
});

let rootEnhancer = compose();
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    rootEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: "velract App-Client"
    })(rootEnhancer);
}

const initialStore = {};
const store = createStore(rootReducer, initialStore, rootEnhancer);
export default store;
