import store from "../redux/store";
import { set_auth } from "../redux/actions";

class AuthHelperContainer {
    check() {
        const state = store.getState();
        return state.app.auth ? true : false;
    }

    getUser() {
        const state = store.getState();
        return state.app.auth ? state.app.auth : null;
    }

    setAuth(auth) {
        store.dispatch(set_auth(auth));

        localStorage.setItem("auth", JSON.stringify(auth));
    }

    getAuthFromLocalStorage() {
        const authLocal = localStorage.getItem("auth");
        if (authLocal) {
            const auth = JSON.parse(authLocal);

            if (auth && auth.api_key && auth.api_key.trim() !== "") {
                return auth;
            }
        }
        return null;
    }

    logout() {
        store.dispatch(set_auth(null));
        localStorage.setItem("auth", "");
    }
}

const AuthHelper = new AuthHelperContainer();
export { AuthHelper };
