import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ApiHelper } from "../../helpers";
import { LoadingSpinner } from "../../components";

class SelectProject extends Component {
    state = {
        isLoading: true,
        project_id: 0
    };

    componentDidMount() {
        this.setState({
            isLoading: true
        });

        setTimeout(() => {
            ApiHelper.request("/projects/list", {}).then(response => {
                this.setState({
                    isLoading: false,
                    projects: response.projects && response.projects.length > 0 ? response.projects : []
                });
            });
        }, 500);
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    submit = e => {
        e.preventDefault();

        if (this.state.project_id > 0) {
            this.props.history.push("/invoices/" + this.state.project_id + "/new/edit");
        }
    };

    render() {
        let form;
        if (this.state.isLoading) {
            form = <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>;
        } else if (this.state.projects && this.state.projects.length > 0) {
            form = (
                <form onSubmit={this.submit}>
                    <div className="form-group">
                        <label>Bauprojekt</label>
                        <select
                            className="form-control"
                            name="project_id"
                            onChange={this.onChange}
                            value={this.state.project_id}
                        >
                            <option value={0}>Bitte auswählen</option>
                            {this.state.projects.map(project => {
                                let label =
                                    project.address.zip +
                                    " " +
                                    project.address.city +
                                    " - " +
                                    project.address.street +
                                    " " +
                                    project.address.housenumber +
                                    " - " +
                                    project.location;

                                return (
                                    <option key={"project_" + project.id} value={project.id}>
                                        {label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="form-group text-right">
                        <input
                            type="submit"
                            value="Weiter"
                            className="btn btn-success"
                            disabled={this.state.project_id < 1 ? true : false}
                        />
                    </div>
                </form>
            );
        } else {
            form = (
                <div className="alert alert-warning">Es stehen keine Bauprojekte zur Rechnungserstellung bereit.</div>
            );
        }

        return (
            <div className="container">
                <div className="content">
                    <h3>
                        Rechnung erstellen <small>> Bauprojekt auswählen</small>
                    </h3>

                    {form}
                </div>
            </div>
        );
    }
}

export default withRouter(SelectProject);
