import React from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./redux/store";
import { AuthHelper } from "./helpers/AuthHelper";
import Layout from "./components/design/Layout";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Invoices from "./screens/Invoices/Invoices";
import InvoiceDetails from "./screens/Invoices/Details";
import CreateInvoice from "./screens/Invoices/CreateInvoice";
import { default as InvoiceSelectProject } from "./screens/Invoices/SelectProject";
import Projects from "./screens/Projects";
import Settings from "./screens/Settings";

const PrivateRoute = props => {
    return AuthHelper.check() ? (
        <Route {...props} />
    ) : (
        <Redirect
            to={{
                pathname: "/",
                state: { from: props.location }
            }}
        />
    );
};

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route exact path="/" component={Login} />

                    <Layout>
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                        <PrivateRoute exact path="/invoices" component={Invoices} />
                        <PrivateRoute exact path="/invoices/:id/details" component={InvoiceDetails} />
                        <PrivateRoute exact path="/invoices/create" component={InvoiceSelectProject} />
                        <PrivateRoute exact path="/invoices/:project/:id/edit" component={CreateInvoice} />
                        <PrivateRoute exact path="/projects" component={Projects} />
                        <PrivateRoute exact path="/settings" component={Settings} />
                    </Layout>
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
