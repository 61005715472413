import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApiHelper, ProjectsHelper } from "../helpers";
import { LoadingSpinner } from "../components";

class Dashboard extends Component {
    state = {
        isLoading: true,
        projects: []
    };

    componentDidMount() {
        setTimeout(() => {
            ApiHelper.request("/projects/list", {}).then(response => {
                this.setState({
                    isLoading: false,
                    projects: response.projects && response.projects.length > 0 ? response.projects : []
                });
            });
        }, 750);
    }

    render() {
        let projects;
        if (this.state.isLoading) {
            projects = (
                <tr>
                    <td colSpan={4}>
                        <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
                    </td>
                </tr>
            );
        } else if (this.state.projects && this.state.projects.length > 0) {
            projects = this.state.projects.map(project => {
                return (
                    <tr key={"project_" + project.id}>
                        <td>
                            {project.address.zip} {project.address.city}
                        </td>
                        <td>
                            {project.address.street} {project.address.housenumber}
                        </td>
                        <td>{project.project_number}</td>
                        <td className="text-right">
                            <div className="btn-group">
                                <a
                                    className="btn btn-sm btn-primary"
                                    href={ProjectsHelper.generateNuAuftragUrl(project)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Auftrag öffnen
                                </a>
                                <Link to={"/invoices/" + project.id + "/new/edit"} className="btn btn-sm btn-primary">
                                    Rechnung erstellen
                                </Link>
                            </div>
                        </td>
                    </tr>
                );
            });
        } else {
            projects = (
                <tr>
                    <td>Keine Bauprojekte vorhanden.</td>
                </tr>
            );
        }

        return (
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="float-right">
                                <Link to="/invoices/create" className="btn btn-secondary">
                                    Rechnung erstellen
                                </Link>
                            </div>
                            <h3>Herzlich willkommen {this.props.auth.name}!</h3>

                            {this.props.app_client && this.props.app_client.subcontractor ? (
                                <small>Nachunternehmer: {this.props.app_client.subcontractor.name}</small>
                            ) : null}
                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="card">
                                <div className="card-header">
                                    <Link to="/projects" className="float-right">
                                        Alle Bauprojekte ansehen
                                    </Link>
                                    Ihre Bauprojekte
                                </div>
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Stadt</th>
                                            <th>Straße</th>
                                            <th>Projekt-Nr.</th>
                                            <th className="text-right">Optionen</th>
                                        </tr>
                                    </thead>
                                    <tbody>{projects}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.app.auth,
    app_client: state.app.app_client
});
export default connect(mapStateToProps)(Dashboard);
